<template>
    <div class="odd-continer">
        <div class="odd-center">
            <div class="lan"></div>
            <div class="odd-span">打印快递单</div>
            <div class="odd-end">
                <div class="end-left">
                    <el-form ref="form" :model="form" label-width="100px">
                        <el-form-item label="已选单数 :">{{num}}</el-form-item>
                        <el-form-item label="物流公司 :">
                            <el-select v-model="form.region" placeholder="请选择物流公司" @change="changer" disabled>
                                <el-option v-for="item in gs" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="快递模板 :">
                            <el-select v-model="form.mo" placeholder="请选择快递模板">
                                <el-option v-for="item in moudel" :key="item.id" :label="item.name" :value="item.templateCode"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="选择打印机 :">
                            <el-col :span="15">
                          <el-input v-model="form.name"></el-input>
                          <span>注：请先</span><span class="do">下载打印机</span>
                          </el-col>
                        </el-form-item> -->
                    </el-form>
                </div>
                <div class="end-right">
                    <span>模板图片（宽{{form.width}}mm*高{{form.height}}mm）</span>
                    <div class="right-img">
                        <!--basedatapdf为base64编码-->
                        <iframe id="addzlhxxIframe" ref="dyImg" frameborder="1" scrolling="NO" :src="'data:application/pdf;base64,'+url" width="100%" height="100%">
                        </iframe>
                        <!-- <img class="my-img"   v-show="false" :preview-src-list="srcList"   :src="url" ref="preview"> -->
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="odd-z"> -->
        <!-- <div class="odd-z1"><el-button size="mini" type="primary" >打印</el-button></div> -->
        <!-- <div class="odd-z2" @click="fn()"><el-button size="mini"  >预览</el-button></div> -->
        <!-- </div> -->
    </div>
</template>

<script>
    import pdf from 'vue-pdf'
    import { urlimg, urlpdf, Template, Company } from '../../api/odd'
    export default {
        components: { pdf },
        data() {
            return {
                num: 1,
                form: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: '',
                    isPrint: false,
                    mo: '',
                    width: 0,
                    height: 0
                },
                showViewer: false,
                url: '',
                gs: [],
                moudel: [],
                waybill: "",
            };
        },
        methods: {
            changer(event) {
                console.log(event)
                this.getTemplate(event)
            },
            //得到物流公司下拉列表
            getCompany() {
                Company().then(res => {
                    console.log(res);
                    if (res.code === 10000) {
                        this.gs = res.data

                    }
                }).catch(err => {
                    console.log(err);
                })
            },
            onSubmit() {
                console.log('submit!');
            },
            geturl() {
                let data = {
                    wayBillNo: this.$route.query.way,
                }
                urlpdf(data).then(res => {
                    console.log(res)
                    if (res.code === 10000) {
                        this.url = res.data
                    } else {
                        this.$message(res.msg)
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            //快递模板列表
            getTemplate(val) {
                let data = {
                    logisticsCompanyCode: val
                }
                Template(data).then(res => {
                    console.log(res);
                    if (res.code === 10000) {
                        this.moudel = res.data
                        this.form.mo = this.moudel[0].templateCode
                        this.form.width = this.moudel[0].width;
                        this.form.height = this.moudel[0].height;

                    }
                }).catch(err => {
                    console.log(err);
                })
            },
            // // 打印
            // print() {
            //   // console.log(this.$refs.dyImg,this.$refs.dyImg.offsetWidth);
            //   // let timer=null
            //   // this.isPrint = true;
            //   // timer=setTimeout(() => {
            //   //   this.$print(this.$refs.dyImg)  //打印
            //   //   this.isPrint = false;
            //   // });
            //   		 if (window.frames['addzlhxxIframe'] != null) {
            //             document.getElementById('addzlhxxIframe').focus();
            //             document.getElementById('addzlhxxIframe').contentWindow.print();
            //         } else {
            //             window.frames['addzlhxxIframe'].focus();
            //             print();
            //         }
            // },

            // 点击按钮预览图片
            fn() {
                this.$refs.preview.showViewer = true
            }
        },
        created() {
            this.geturl()
            this.getCompany()
            console.log(this.$route.query.way)
            this.form.region = parseInt(this.$route.query.logisticsCompanyId)
            this.getTemplate(this.form.region)
            // this.waybill=this.$route.query
            // console.log(this.waybill.way)
        }
    };
</script>

<style scoped>
      .odd-continer {
          width: 100%;
          height: 100%;
          background: white;
      }

      .odd-center {
          width: 1011px;
          height: 500px;
          margin: 20px auto;
          position: relative;
      }

      .lan {
          width: 3px;
          height: 18px;
          background: #0084ff;
          position: absolute;
          left: 5px;
          top: 3px;
      }

      .odd-span {
          color: #333333;
          font-family: Microsoft YaHei;
          font-weight: bold;
          text-indent: 25px;
          /* line-height: 80px; */
          /* margin-top: 20px;
    margin-left: 20px; */
      }

      .odd-end {
          width: 100%;
          height: 470px;
          margin-top: 30px;
          /* padding: 10px 20px 0 20px;
    box-sizing: border-box; */
      }

      .end-left {
          width: 70%;
          height: 100%;
          float: left;
      }

      .end-right {
          width: 30%;
          height: 100%;
          float: left;
      }

      .do {
          color: #0084FF;
      }

      .right-img {
          width: 100%;
          height: 410px;
          /* background: pink; */
          margin-top: 38px;
      }

          .right-img img {
              width: 100%;
              height: 100%;
          }

      .odd-z {
          width: 100%;
          height: 50px;
          margin-top: 50px;
      }

      .odd-z1 {
          float: right;
          margin-right: 30px;
      }

      .odd-z2 {
          float: right;
          margin-right: 40px;
      }

      .my-img {
          width: 100px;
          height: 100px;
      }

          .my-img .el-image__inner,
          .el-image__error {
              visibility: hidden;
          }
</style>
