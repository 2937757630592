import request from '../utils/request'
import baseURL from '../utils/config'

//得到电子打印面单文件url地址
export function urlimg(data) {
    return request({
        url: baseURL.baseURL + 'api/Synthetical/GetCloudPrintWaybillFileUrl',
        method: 'get',
        params:data,
    })
}
//得到电子打印面单pdf文件url地址
export function urlpdf(data) {
    return request({
        url: baseURL.baseURL + 'api/Synthetical/GetCloudPrintWaybillFileBase64',
        method: 'get',
        params: data,
    })
}

//得打打印面单的模板列表
export function Template(data) {
  return request({
      url: baseURL.baseURL + 'api/Synthetical/GetPrintTemplate',
      method: 'get',
      params:data,
  })
}
//得打物流公司列表
export function Company() {
    return request({
        url: baseURL.baseURL + 'api/Adm_Company/Drop_LogisticsCompany',
        method: 'get',
    })
  }